<template>
  <component :is="component" v-bind="$attrs" />
</template>

<script>
// const icons = [
//   'burger',
//   'cross',
//   'arrow-down',
//   'arrow-up',
//   'arrow-left',
//   'arrow-right'
// ];

export default {
  name: 'UIcon',
  props: {
    name: {
      type: String,
      required: true
      // validator: (value) => icons.includes(value)
    }
  },
  computed: {
    component() {
      return require(`@/assets/icons/${this.name}.svg?inline`);
    }
  }
};
</script>
